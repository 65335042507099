import React, { useState, useEffect } from 'react'
import { Button } from '../../common/button'
import Input from '../../common/input'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

const SubscribeForm = ({ text }) => {
    const { t, i18n } = useTranslation();
    const methods = useForm();

    useEffect(() => {
        const loadReCAPTCHAScript = () => {
            const script = document.createElement('script');
            script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
            script.async = true;
            document.body.appendChild(script);
        };
        loadReCAPTCHAScript();
    }, []);

    const onSubmit = (data) => {
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' }).then(async (token) => {
                data.recaptcha_token = token;
                console.log(data);
            })
        })
    }

    return (
        <FormProvider {...methods}>
            <form className='flex flex-row justify-between items-start' onSubmit={methods.handleSubmit(onSubmit)}>
                <Input type='email' placeholder={t('footer-email')} name='email' label='Email' />
                <button type='submit' className='-ml-4'>
                    <Button className='button-secondary h-[48px] items-center'>
                        {text}
                    </Button>
                </button>
            </form>
        </FormProvider>
    )
}

export default SubscribeForm
