import Coins from '../assets/image/coins.png'
import Hemisphere from '../assets/image/globe-hemisphere-west.png'
import Shield from '../assets/image/shield-checkered.png'
import Star from '../assets/image/star.png'
import Tag from '../assets/image/tag-simple.png'
import Wallet from '../assets/image/wallet.png'

import BankSvg from '../assets/svg/bank-card.svg'
import CashSvg from '../assets/svg/cash.svg'
import CoinsSvg from '../assets/svg/coins.svg'
import RefundSvg from '../assets/svg/refund.svg'
import ShieldSvg from '../assets/svg/shield-flash.svg'
import WalletSvg from '../assets/svg/wallet.svg'
import BitCoinSvg from '../assets/svg/bit-coin.svg'
import CurrencySvg from '../assets/svg/currency.svg'
import DiscountPercentSvg from '../assets/svg/discount-percent.svg'
import InstagramSvg from '../assets/svg/instagram.svg'
import LinkdinSvg from '../assets/svg/linkedin.svg'
import TwitterSvg from '../assets/svg/twitter.svg'

export const navItems= [
    {
        label: 'header-item1',
        link: '#aboutus'
    },
    {
        label: 'header-item2',
        link: '#how-solar-card-works'
    },
    {
        label: 'header-item3',
        link: '#features'
    },
    {
        label: 'header-item4',
        link: '#faq'
    },
]

export const aboutData = [
    {
        icon: BankSvg,
        label: 'about-card1-header',
        text: 'about-card1-content',
        background: Hemisphere,
        width: '145px'
    },
    {
        icon: CoinsSvg,
        label: 'about-card2-header',
        text: 'about-card2-content',
        background: Coins,
        width: '174px'
    },
    {
        icon: WalletSvg,
        label: 'about-card3-header',
        text: 'about-card3-content',
        background: Wallet,
        width: '189px'
    },
    {
        icon: ShieldSvg,
        label: 'about-card4-header',
        text: 'about-card4-content',
        background: Shield,
        width: '145px'
    },
    {
        icon: RefundSvg,
        label: 'about-card5-header',
        text: 'about-card5-content',
        background: Star,
        width: '145px'
    },
    {
        icon: CashSvg,
        label: 'about-card6-header',
        text: 'about-card6-content',
        background: Tag,
        width: '145px'
    },
]

export const pricingData = [
    {
        label: 'price-card1-header',
        price: 'price-card1-price',
        description: 'price-card1-description',
        items: [
            'price-card1-content1',
            'price-card1-content2',
            'price-card1-content3',
            'price-card1-content4',
        ],
        button: {
            type: 'secondary',
            label: 'price-card1-button'
        },
        active: false
    },
    {
        label: 'price-card2-header',
        price: 'price-card2-price',
        description: 'price-card2-description',
        items: [
            'price-card2-content1',
            'price-card2-content2',
            'price-card2-content3',
            'price-card2-content4',
        ],
        button: {
            type: 'primary',
            label: 'price-card2-button'
        },
        active: true
    },
    {
        label: 'price-card3-header',
        price: 'price-card3-price',
        description: 'price-card3-description',
        items: [
            'price-card3-content1',
            'price-card3-content2',
            'price-card3-content3',
            'price-card3-content4',
        ],
        button: {
            type: 'secondary',
            label: 'price-card3-button'
        },
        active: false
    },
]

export const cryptoData = [
    {
        icon: CurrencySvg,
        title: "crypto-card1-header",
        description: "crypto-card1-description"
    },
    {
        icon: BitCoinSvg,
        title: "crypto-card2-header",
        description: "crypto-card2-description"
    },
    {
        icon: DiscountPercentSvg,
        title: "crypto-card3-header",
        description: "crypto-card3-description"
    }
]

export const faqData = [
    {
        question: "faq-content1-question",
        answer: "faq-content1-answer"
    },
    {
        question: "faq-content2-question",
        answer: "faq-content2-answer"
    },
    {
        question: "faq-content3-question",
        answer: "faq-content3-answer"
    }
]

export const footerLinks = [
    {
        label: "Privacy Policy",
        link: ""
    },
    {
        label: "Terms of Service",
        link: ""
    }
]

export const footerIcons = [
    {
        icon: TwitterSvg,
        label: 'twitter',
        link: ""
    },
    {
        icon: InstagramSvg,
        label: 'instagram',
        link: ""
    },
    {
        icon: LinkdinSvg,
        label: 'linkdin',
        link: ""
    },
]

export const worksData = [
    {
        type: 'works-sign',
        label: 'works-step1-label',
        title: 'works-step1-title',
        description: 'works-step1-description'
    },
    {
        type: 'works-load',
        label: 'works-step2-label',
        title: 'works-step2-title',
        description: 'works-step2-description'
    },
    {
        type: 'works-spend',
        label: 'works-step3-label',
        title: 'works-step3-title',
        description: 'works-step3-description'
    }
]

export const access_link = 'https://github.com/solarenterprises/Solar-Card-mobile/releases/download/Android/solar-card-release.apk';