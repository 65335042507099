import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { faqData } from '../config/const';
import { GhostButton } from '../component/common/button';
import { TransComponents } from '../locales/i18n';

const FAQ = () => {
    const { t, i18n } = useTranslation();

    return (
        <section id='faq' className='md:px-20 px-4 flex md:flex-row flex-col justify-between gap-[24px] items-start'>
            <h2>{t("faq-title")}</h2>
            <div className='lg:w-[762px] w-auto flex flex-col items-start md:gap-16 gap-10'>
                <div className='flex flex-col items-start gap-8 self-stretch'>
                    {faqData.map((data, index) =>
                        <div className='flex items-start gap-6 self-stretch' key={`faq-${index}`}>
                            <label className='pt-[6px]'>0{index + 1}</label>
                            <div className='flex flex-col items-start gap-6 flex-[1_0_0]'>
                                <h3>{t(data.question)}</h3>
                                <p><span className='opacity-60 text-[16px]'>
                                    <Trans
                                        components={{
                                            ...TransComponents,
                                        }}>
                                        {t(data.answer)}
                                    </Trans>
                                </span></p>
                            </div>
                        </div>
                    )}
                    <div className='pl-10'>
                        <GhostButton text={t('faq-button')} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FAQ
